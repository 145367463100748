import { default as calculatewe9sGcEVigMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/calculatietool/pages/calculate.vue?macro=true";
import { default as indexRo0Za6tjQBMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/calculatietool/pages/index.vue?macro=true";
export default [
  {
    name: calculatewe9sGcEVigMeta?.name ?? "calculate",
    path: calculatewe9sGcEVigMeta?.path ?? "/calculate",
    meta: calculatewe9sGcEVigMeta || {},
    alias: calculatewe9sGcEVigMeta?.alias || [],
    redirect: calculatewe9sGcEVigMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/calculatietool/pages/calculate.vue").then(m => m.default || m)
  },
  {
    name: indexRo0Za6tjQBMeta?.name ?? "index",
    path: indexRo0Za6tjQBMeta?.path ?? "/",
    meta: indexRo0Za6tjQBMeta || {},
    alias: indexRo0Za6tjQBMeta?.alias || [],
    redirect: indexRo0Za6tjQBMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/109/nuxt/calculatietool/pages/index.vue").then(m => m.default || m)
  }
]