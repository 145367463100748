export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"mobile-web-app-capable","content":"yes","key":"mobile-web-app-capable"},{"name":"theme-color","content":"#000000","key":"theme-color"},{"property":"og:type","content":"website","key":"og:type"},{"property":"og:title","content":true,"key":"og:title"},{"property":"og:site_name","content":true,"key":"og:site_name"},{"name":"twitter:card","content":"summary","key":"twitter:card"}],"link":[{"rel":"icon","href":"/_nuxt/icons/64x64.578934eb.png","key":"favicon"},{"rel":"apple-touch-icon","href":"/_nuxt/icons/512x512.maskable.578934eb.png","sizes":"512x512","key":"favicon-apple"},{"rel":"manifest","href":"/manifest.json"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"en"}}

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false